.timer {
  display: flex;
  flex-direction: column;
  background-color: #fbf1c2;
  padding: 50px 40px;
  height: calc(100% + 70px);
  margin-top: -35px;

  &__icon {
    text-align: center;
    margin-bottom: 20px;

    img {
      height: 64px;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  &__content {
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center;
  }

  @media (max-width: 1199px) {
    padding: 40px 30px;

    &__title {
      font-size: 20px;
    }

    &__content {
      font-size: 18px;
    }
  }

  @media (max-width: 991px) {
    height: auto;
    margin: 0 auto;
    max-width: 350px;
  }
}

.about {
  &__title {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    color: $primary;
    margin-bottom: 20px;
  }

  @media (max-width: 991px) {
    &__title {
      font-size: 24px;
    }
  }
}

.doctor-info {
  &__avatar {
    @include frame("circle");
    width: 280px;
    max-width: 25%;
    margin-right: 30px;
  }

  &__name {
    font-size: 35px;
    font-weight: 700;
    color: $primary;
  }

  &__info {
    margin-top: 15px;
    padding-left: 40px;
    position: relative;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;

    img {
      height: 24px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  @media (max-width: 1199px) {
    &__avatar {
      width: 200px;
    }

    &__name {
      font-size: 30px;
    }
  }

  @media (max-width: 991px) {
    &__avatar {
      margin-right: 15px;
    }

    &__name {
      font-size: 24px;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;

    &__avatar {
      margin-right: 0;
      margin-bottom: 15px;
      max-width: 60%;
    }

    &__body {
      width: 100%;
      margin-bottom: 15px;
    }

    &__name {
      font-size: 20px;
      text-align: center;
    }

    &__info {
      padding: 0 40px;
      padding-right: 0;
    }
  }
}
