.footer {
  padding: 50px 0 0;
  // background: url("../images/footer-bg.jpg") center/cover no-repeat;
  background-position: 100% 50%;
  background-color: #2f2f2f;

  &__grid {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 210px 1fr 270px 270px;
    margin-bottom: 50px;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    color: $white;
    margin-bottom: 10px;
  }

  &__logo {
    display: inline-flex;

    img {
      max-height: 52px;
    }
  }

  &__address {
    font-size: 16px;
    color: $white;

    a,
    a:hover {
      color: $white;
    }
  }

  &__map {
    height: 100%;
    min-height: 150px;
  }

  &__copyright {
    border-top: 1px solid $white;
    color: $white;
    text-align: center;
    padding: 16px 0 36px;
  }

  @media (max-width: 1199px) {
    &__grid {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: 767px) {
    &__grid {
      grid-template-columns: 1fr;
    }

    &__title {
      font-size: 18px;
    }

    &__address {
      font-size: 14px;
    }
  }
}

.f-contact {
  list-style: none;
  padding-left: 0;
  margin-bottom: 16px;

  &__item {
    padding-left: 44px;
    position: relative;

    img {
      height: 19px;
      width: 23px;
      object-fit: contain;
      object-position: 50% 50%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &,
    a,
    a:hover {
      color: $white;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.social {
  display: flex;

  &__item {
    display: flex;

    &:not(:last-child) {
      margin-right: 10px;
    }

    img {
      height: 35px;
    }
  }
}
