.section {
  padding: 35px 0;

  &--light {
    background-color: #e4f1dd;
  }

  &__title {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 15px;

    &::after {
      content: "";
      display: block;
      width: 150px;
      border-bottom: 2px solid $primary;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &--left {
      text-align: left;

      &::after {
        left: 0;
        transform: translateX(0);
      }
    }
  }

  @media (max-width: 991px) {
    &__title {
      font-size: 24px;
      padding-bottom: 8px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 20px;
    }
  }
}
