.btn {
  &-default {
    border-color: $border-color;
    background-color: transparent;

    &:hover {
      border-color: $primary;
      color: $primary;
    }
  }

  &-rounded {
    border-radius: 18px;
  }

  &-shine {
    @include btn("shine");
  }

  &-fill {
    @include btn("fill");
  }

  &-fill.btn-primary {
    &::before {
      background-color: $white;
    }

    &:hover {
      color: $primary;
    }
  }

  &-fill.btn-danger {
    &::before {
      background-color: $white;
    }

    &:hover {
      color: $danger;
    }
  }

  &-fill.btn-outline-primary {
    &::before {
      background-color: $primary;
    }

    &:hover {
      color: $white;
      background-color: $white;
    }
  }

  &-fill.btn-outline-danger {
    &::before {
      background-color: $danger;
    }

    &:hover {
      color: $white;
      background-color: $white;
    }
  }

  &-neon-orange {
    @include btn-neon("orange");
  }

  &-neon-green {
    @include btn-neon("green");
  }

  &-neon-purple {
    @include btn-neon("purple");
  }

  &-neon-blue {
    @include btn-neon("blue");
  }

  &-neon-dark {
    @include btn-neon("dark");
  }
}

$rounded-list: 8 10 18 20 22 24 25 26 28 30;
@each $item in $font-size-list {
  .rounded-#{$item} {
    border-radius: #{$item}px;
  }
}

.button {
  display: inline-block;
  padding: 10px 40px;
  font-size: 16px;
  font-weight: 700;
  color: $white;
  border-radius: 22px;
  background: $primary;
  text-transform: uppercase;
  border: 0;
  transition: 0.3s;
  position: relative;
  z-index: 1;

  &:hover {
    background-color: darken($primary, 5%);
    text-decoration: none;
    color: $white;
  }

  &.is-loading {
    pointer-events: none;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 22px;
      background-color: rgba(#fff, 0.4);
      position: absolute;
      top: 0;
      left: 0;
    }

    &::after {
      content: " ";
      display: block;
      width: 30px;
      height: 30px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: 2px solid #fff;
      border-color: #fff transparent #fff transparent;
      animation: button-loading 1.2s linear infinite;
    }
  }

  @media (max-width: 991px) {
    padding: 7px 30px;
    font-size: 14px;
    border-radius: 18px;
  }
}

@keyframes button-loading {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
