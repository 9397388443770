.banner-slider {
  position: relative;
  z-index: 1;

  &__prev,
  &__next {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
    font-size: 20px;
    color: $white;
    background-color: rgba(#000, 0.3);

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: 5%;
  }

  @media (max-width: 767px) {
    &__prev,
    &__next {
      width: 30px;
      height: 30px;
    }
  }
}
