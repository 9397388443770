$gutters: 4 8 10 16 20 30 40 50 60 80 100;

.gutter {
  @each $gutter in $gutters {
    &.-all-#{$gutter} {
      margin: 0 -#{$gutter / 2}px;
      & > [class^="col"] {
        padding: 0 #{$gutter / 2}px;
      }
    }
  }

  @each $gutter in $gutters {
    @each $breakpoint in map-keys($grid-breakpoints) {
      @include media-breakpoint-up($breakpoint) {
        &.-over-#{$breakpoint}-#{$gutter} {
          margin: 0 -#{$gutter / 2}px;
          & > [class^="col"] {
            padding: 0 #{$gutter / 2}px;
          }
        }
      }

      @include media-breakpoint-down($breakpoint) {
        &.-under-#{$breakpoint}-#{$gutter} {
          margin: 0 -#{$gutter / 2}px;
          & > [class^="col"] {
            padding: 0 #{$gutter / 2}px;
          }
        }
      }
    }
  }
}

// add column 20% (5 items in a row)
.col-20 {
  flex: 0 0 20%;
  max-width: 20%;
  padding: 0 15px;
}

@each $key, $val in $grid-breakpoints {
  .col-#{$key}-20 {
    padding: 0 15px;
  }
  @media (min-width: #{$val}) {
    .col-#{$key}-20 {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
}

.news-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;

  &__col {
    &:first-child {
      grid-column: span 2;
      grid-row: span 2;
      position: relative;
    }
  }

  @media (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
  }
}
