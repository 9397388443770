.post {
  &-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  &-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #666;
    margin-bottom: 16px;
  }

  &-sapo {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  &-content {
    h1 {
      font-size: 30px;
      font-weight: 700;
      text-transform: uppercase;
      color: $primary;
      margin-bottom: 20px;
    }

    h2 {
      font-size: 20px;
      font-weight: 700;
      color: $primary;
      margin: 16px 0;
    }
  }
}
