.logo {
  display: inline-flex;
  margin: 10px 0;

  img {
    height: 55px;
  }

  @media (min-width: 1200px) {
    margin: 20px 0;

    img {
      height: 60px;
    }
  }
}

.header {
  position: relative;
  z-index: 100;

  &__inner {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
  }

  &__elements {
    display: flex;
    align-items: center;
    margin-left: auto;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  &__btn {
    display: inline-flex;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    position: relative;
    color: $gray-800;
    font-size: 20px;

    & + & {
      margin-left: 4px;
    }

    &:hover {
      color: $primary;
      text-decoration: none;
    }

    span {
      display: inline-flex;
      width: 15px;
      height: 15px;
      justify-content: center;
      align-items: center;
      background-color: $primary;
      border-radius: 50%;
      font-size: 11px;
      font-weight: 700;
      text-align: center;
      color: $white;
      position: absolute;
      top: 0;
      right: 0;
    }

    img {
      max-height: 16px;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  @media (min-width: 1200px) {
    &__inner {
      justify-content: flex-start;
    }

    &__btn {
      display: none;
    }
  }
}

.navigation {
  background-color: #006300;

  &__inner {
    position: relative;
  }
}

.h-contact {
  display: flex;
  padding: 8px 0;

  &__item {
    flex: 1;
    align-items: center;
    color: $white;
    font-size: 12px;

    a,
    a:hover {
      color: $white;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__icon {
    margin-right: 10px;
  }

  @media (min-width: 1200px) {
    display: flex;
    position: absolute;
    top: -50px;
    right: 0;
    transform: translateY(-50%);
    margin: 0 300px 0 0;

    &__item {
      flex: auto;
      color: #333;
      max-width: 330px;
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 14px;

      &:not(:last-child) {
        margin-right: 30px;
      }

      a,
      a:hover {
        color: #fe0000;
      }
    }

    &__icon {
      display: flex;
      width: 45px;
      height: 45px;
      justify-content: center;
      align-items: center;
      border: 7px solid rgba($primary, 0.5);
      border-radius: 50%;
      padding: 4px;
      margin-right: 15px;
      background-color: $primary;
      background-clip: padding-box;
    }
  }
}

.search {
  padding: 10px 20px 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid $border-color;

  .form-control {
    border-right: 0;

    &:focus {
      border-color: #ddd;
    }
  }

  .input-group-text {
    background-color: $white;
    color: $primary;
  }

  @media (min-width: 1200px) {
    width: 270px;
    padding: 0;
    margin: 0;
    border: 0;
    position: absolute;
    top: -50px;
    right: 0;
    transform: translateY(-50%);

    .form-control {
      border: 1px solid $primary;
      border-right: 0;
      padding-left: 16px;

      &:focus {
        border-color: $primary;
      }
    }

    .input-group-text {
      border: 1px solid $primary;
      border-left: 0;
    }
  }
}
