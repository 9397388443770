.news {
  &__frame {
    @include frame("rect", 60%);
    @include frame("zoom");
    width: 150px;
    margin-right: 15px;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__time {
    color: #666;
  }

  @media (max-width: 1199px) {
    &__frame {
      width: 120px;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;

    &__frame {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }

    &__time {
      font-size: 12px;
    }
  }
}

.news-2 {
  position: relative;
  z-index: 1;
  height: 100%;

  &__frame {
    @include frame("rect", 56.25%);
    @include frame("zoom");
    height: 100%;
  }

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 70px;
    margin: 0;
    padding: 8px 16px;
    background-color: rgba($primary, 0.5);
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.3;
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;

    &,
    a,
    a:hover {
      color: $white;
    }

    a {
      display: block;
      @include max-line(2);
      max-height: 2.6em;
    }
  }

  @media (max-width: 1599px) {
    &__title {
      font-size: 14px;
      min-height: 50px;
    }
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 12px;
      padding: 4px 10px;
    }
  }
}

.category {
  &__frame {
    display: flex;
    width: 145px;
    height: 145px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid $primary;
    background-color: $white;
    box-shadow: 0 0 0 5px $white;
    margin: 0 auto;
    padding: 16px;
    overflow: hidden;
    position: relative;
    z-index: 1;

    img {
      object-fit: contain;
      object-position: 50% 50%;
      transition: 0.3s;
      position: relative;
      z-index: -1;
    }

    &:hover img {
      transform: scale(1.05);
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    color: $primary;
    margin: 20px 0 0;
  }
}

.doctor {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 8px 8px 30px;
  border: 1px solid $primary;
  border-radius: 20px;

  &__frame {
    @include frame("square");
    @include frame("zoom");
    border-radius: 20px;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    color: $primary;
    margin: 16px 0 24px;
  }

  @media (max-width: 767px) {
    padding: 4px 4px 10px;

    &__title {
      font-size: 14px;
      line-height: 1.3;
      margin: 10px 0;
    }
  }
}

.cat {
  @include shadow("");
  padding: 15px 15px 24px;
  border-radius: 20px;
  height: 100%;
  transition: 0.4s;

  &:hover {
    @include shadow("md");
  }

  &__frame {
    display: flex;
    height: 115px;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;

    img {
      max-height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
      transition: 0.3s;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    padding: 8px;

    &__frame {
      height: 80px;
      margin-bottom: 8px;
    }

    &__title {
      font-size: 16px;
    }
  }
}

.faq {
  & + & {
    margin-top: 10px;
  }

  &__header {
    align-items: center;
    background-color: $primary;
    padding: 10px 20px;
    color: $white;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;

    img {
      height: 30px;
      margin-right: 20px;
    }
  }

  &:first-child &__header {
    border-radius: 4px 4px 0 0;
  }

  &__icon {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid $white;
    color: $white;
    transition: 0.3s;
  }

  &__header.active &__icon {
    transform: rotate(90deg);
  }

  &__body {
    display: none;
    background-color: $white;
    padding: 20px;
    border-radius: 0 0 4px 4px;
  }
}
