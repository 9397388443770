$font-size-list: 8 10 12 13 14 15 16 18 20 22 24 25 26 28 30;
$font-weight-list: 300 400 500 600 700 800 900;
$text-color-list: "111", "222", "333", "444", "555", "666", "777", "888", "999",
  "aaa", "bbb", "ccc", "ddd", "eee", "fff";

// add limit line for text
%max-line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: $line-height-base;
}

.text {
  &-italic {
    font-style: italic;
  }

  &-left {
    text-align: left;
  }

  &-right {
    text-align: right;
  }

  &-justify {
    text-align: justify;
  }

  &-center {
    text-align: center;
  }

  &-uppercase {
    text-transform: uppercase;
  }

  &-underline {
    text-decoration: underline;
  }

  &-default {
    display: inline;
    background: 0;
    font: inherit;
    margin: 0;
    padding: 0;

    &,
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  // More font weight
  @each $item in $font-weight-list {
    &-#{$item} {
      font-weight: #{$item};
    }
  }

  // More font-size
  @each $item in $font-size-list {
    &-#{$item} {
      font-size: #{$item}px;
    }
  }

  @each $key, $val in $grid-breakpoints {
    @media (min-width: #{$val}) {
      @each $item in $font-size-list {
        &-#{$key}-#{$item} {
          font-size: #{$item}px;
        }
      }
    }
  }

  // add text color
  @each $item in $text-color-list {
    &-#{$item} {
      color: #{"#" + $item};
    }
  }

  @each $item in 1 2 3 4 5 6 7 8 9 10 {
    &-#{$item}-line {
      @extend %max-line;
      -webkit-line-clamp: #{$item};
      max-height: #{$item * 1.5}em;
    }
  }
}
